import { useState } from "react";
import parse from 'html-react-parser';
import EditAccordion from "./EditAccordion";

const Accordion = ({ id, title, content, category, categories, handleClickEditNote, showEditNote, cancelNote, editNote, deleteNote }) => {

    const [test, setTest] = useState(true);
    const [showEdit, setShowEdit] = useState(false);

    const clickEditNote = () => {
        handleClickEditNote();
        setShowEdit(true);
        /*console.log(id);
        console.log(title);
        console.log(content);
        console.log(categories);*/
    }

    const clickCancelNote = () => {
        cancelNote();
        setShowEdit(false);
    }

    const handleChange = (data) => {
        //console.log(data);
    }

    return (
        <>
            {showEdit &&
                (
                    <EditAccordion
                        id={id}
                        title={title}
                        content={content}
                        category={category}
                        categories={categories}
                        showEditNote={showEditNote}
                        handleClickEditNote={handleClickEditNote}
                        cancelNote={clickCancelNote}
                        editNote={editNote}
                        deleteNote={deleteNote}
                    />
                )}

            {!showEditNote && (
                <div className="tab">
                    <input className="accordion" type="checkbox" id={id} value={test} onChange={() => handleChange("test")} />
                    <label className="tab-label" id={id}>{title}</label>
                    <div className="tab-content">
                        {parse(content)}
                        <button className='edit' onClick={() => clickEditNote()} > edit</button>
                    </div>
                </div >
            )
            }
        </>
    )
    /* Note: consider making the label and content editable right in the container that they live for future changes*/
}

export default Accordion;