import React, { useEffect, useCallback } from 'react';
import { EditorContent } from '@tiptap/react';

const Tiptap = ({ editor }) => {
    const [isEditable, setIsEditable] = React.useState(true);
    const widthRef = React.useRef(null);
    const heightRef = React.useRef(null);

    useEffect(() => {
        if (widthRef.current && heightRef.current) {
            widthRef.current.value = 640;
            heightRef.current.value = 480;
        }
    }, [widthRef.current, heightRef.current])

    useEffect(() => {
        if (editor) {
            editor.setEditable(isEditable)
        }
    }, [isEditable, editor])

    const addYoutubeVideo = () => {
        const url = prompt('Enter YouTube URL')

        if (url) {
            editor.commands.setYoutubeVideo({
                src: url,
                width: '80%',
                height: '80%',
            })
        }
    }

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href;
        let url = window.prompt('URL', previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
            return;
        }


        // add protocol if missing
        if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('//')) {
            url = `http://${url}`;
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }, [editor]);

    if (!editor) {
        return null
    }

    return (
        <>
            <div className="floating-menu-row">
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
                    H2
                </button>
                <button onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}>
                    <strong>b</strong>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}>
                    <em>i</em>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    className={editor.isActive('underline') ? 'is-active' : ''}>
                    <span style={{ textDecoration: 'underline' }}>u</span>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    ul
                </button>
                <button onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
                    link
                </button>
                <button
                    onClick={() => editor.chain().focus().unsetLink().run()}
                    disabled={!editor.isActive('link')}
                >
                    un-link
                </button>
                <button id="add" onClick={addYoutubeVideo}>video</button>
            </div>
            <EditorContent editor={editor} />
        </>
    )
}

export default Tiptap;