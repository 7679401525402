import { useEffect, useState } from "react";
import { nanoid } from 'nanoid';

import Header from './components/Header';
import AccordionList from './components/AccordionList';

const App = () => {
  //array of saved notes
  const [notes, setNotes] = useState([]);
  const [categories, setCategories] = useState([]);

  //used to hide elements when adding or editing a note
  const [showAddNote, setShowAddNote] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);

  //set notes if saved
  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem('aileyn-notes'));

    if (savedNotes) {
      //console.log('saved notes');
      //console.dir(savedNotes);

      //const newArray = notes.filter((note) => note.title !== '');
      //const newArray = savedNotes.filter((obj) => obj.hasOwnProperty('title'));

      //console.log('new array');
      //console.dir(newArray);
      //setNotes(newArray);
      setNotes(savedNotes);
      updateCategories();
    }
  }, []);

  //update notes array when changed
  useEffect(() => {
    localStorage.setItem('aileyn-notes', JSON.stringify(notes));
    updateCategories();
  }, [notes]);

  const updateCategories = () => {
    const uniqueCategories = [...new Set(notes.map(note => note.category))];
    //console.log(uniqueCategories);
    setCategories(uniqueCategories);
  }

  const handleClickAddNote = () => {
    setShowAddNote(true);
  }

  const handleClickEditNote = () => {
    setShowEditNote(true);
  }

  //if note is updated, add to notes array
  const addNote = (title, content, category) => {
    setShowAddNote(false);
    //const date = new Date();

    const newNote = {
      id: nanoid(),
      title: title,
      content: content,
      category: category,
    }
    //append new note to the notes list
    const newNotes = [...notes, newNote];
    setNotes(newNotes);

    if (categories.some((categoryName) => categoryName === category)) {
      //console.log('category already exists');
      //console.dir(categories);
      //console.dir({ category });
    } else {
      const newCategories = [...categories, category];
      setCategories(newCategories);
    }
  }

  const cancelNote = () => {
    setShowAddNote(false);
    setShowEditNote(false);
  }

  const deleteNote = (id) => {
    //console.log('id ' + id);
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
    cancelNote();

    updateCategories();
  }

  const editNote = (id, title, content, category) => {
    const editedNote = {
      id: id,
      title: title,
      content: content,
      category: category
    }

    const newNotes = notes.map((note) => (note.id === id ? editedNote : note));
    setNotes(newNotes);

    if (categories.some((categoryName) => categoryName === category)) {
      //console.log('category already exists');
      //console.dir(categories);
      //console.dir({ category });
    } else {
      const newCategories = [...categories, category];
      setCategories(newCategories);
    }

    updateCategories();
  }

  return (
    <div>
      <div className="bg">
        <a id="Top"></a>
        <div className="mainContainer">
          <Header />

          <AccordionList
            notes={notes}
            categories={categories}
            showAddNote={showAddNote}
            showEditNote={showEditNote}
            handleClickAddNote={handleClickAddNote}
            handleClickEditNote={handleClickEditNote}
            addNote={addNote}
            cancelNote={cancelNote}
            deleteNote={deleteNote}
            editNote={editNote} />
        </div>
      </div>
    </div>
  )
  //summary text is dead space, add in secondary tab style bar to change what page is being looked at
  //add search/category filters below this bar to search within the section
}

export default App;
