import { useState } from "react";
import Tiptap from "./TipTap";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Youtube from '@tiptap/extension-youtube';


const AddAccordion = ({ categories, showAddNote, handleClickAddNote, addNote, cancelNote }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link.configure({
                openOnClick: true,
            }),
            Youtube
        ],
        content: '<p></p>',
        editorProps: {
            attributes: {
                spellcheck: 'true',
            },
        },
    })

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('life');
    const [newCategory, setNewCategory] = useState('');
    const [completed, setCompleted] = useState(true);

    const clearNote = () => {
        setCompleted(true);
        setTitle('');
        setCategory('life');
        setNewCategory('');
        editor.chain().setContent('<p></p>').run();
        cancelNote();
    }

    const addNewNote = ({ title, content, category, newCategory, editor }) => {
        /*console.log('title: ' + title);
        console.log('content: ' + content);
        console.log('category: ' + category);
        console.log('category: ' + newCategory);*/

        const selectedCategory = newCategory === '' ? category : newCategory.toLowerCase();

        if ((selectedCategory === 'add category') || (title === '') || content === '<p></p>') {
            //console.log('missing data');
            setCompleted(false);
        } else {
            content.replace(/<p><\/p>/g, '<br>');
            setCompleted(true);
            addNote(title, content, selectedCategory);
            clearNote();
        }
    }

    return (
        <div>
            {!showAddNote ? (
                <button onClick={handleClickAddNote}>+ Add Note</button>
            ) : (
                <div className="add-accordion">
                    <div className="input-set">
                        <label>Title</label>
                        <input className={(!completed && title === '') ? ('error') : ('no-error')} type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>

                    <div className='flex-set'>
                        <div className="input-set">
                            <label>Category</label>
                            <select id='selectCategory' value={category} onChange={(e) => setCategory(e.target.value)}>
                                {categories.length > 0 && (
                                    categories.map((categoryName, index) => (
                                        <option key={index} value={categoryName}>
                                            {categoryName}
                                        </option>
                                    ))
                                )}
                                <option value='add category'>add category</option>
                            </select>
                        </div>
                        {((category === 'add category') || (categories.length === 0)) && (
                            <div className="input-set">
                                <label>Category Name</label>
                                <input className={(!completed && newCategory === '') ? ('error') : ('no-error')} type="text" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
                            </div>)}
                    </div>

                    <div className="input-set tiptap">
                        <label className={(!completed && editor.getHTML() === '<p></p>') ? ('tiptap-error') : ('no-error')}>Content</label>
                        <Tiptap editor={editor} />
                    </div>

                    {(!completed && (title === '' || (category === 'add category' && newCategory === '') || editor.getHTML() === '<p></p>')) && (
                        < div className="error">
                            <label>You are missing information.  Fill in all fields to continue.</label>
                        </div>
                    )}

                    <div className="button-set">
                        <button className="add" onClick={() => addNewNote({ title, content: editor.getHTML(), category, newCategory, editor })}>Add</button>
                        <button className="cancel" onClick={clearNote}>cancel</button>
                    </div>
                </div>
            )
            }
        </div >
    )

    /*<input type="text" value={content} onChange={(e) => setContent(e.target.value)} />*/
}

export default AddAccordion;