import { useState } from "react";
import Tiptap from "./TipTap";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Youtube from '@tiptap/extension-youtube';


const EditAccordion = ({ id, title, content, category, categories, showEditNote, handleClickEditNote, cancelNote, editNote, deleteNote }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link.configure({
                openOnClick: true,
            }),
            Youtube
        ],
        content: content,
        editorProps: {
            attributes: {
                spellcheck: 'true',
            },
        },
    })

    const [currentTitle, setCurrentTitle] = useState(title || '');
    const [currentCategory, setCurrentCategory] = useState(category || 'add category');
    const [newCategory, setNewCategory] = useState('');
    const [completed, setCompleted] = useState(true);

    const clearNote = () => {
        setCompleted(true);
        setCurrentTitle('');
        setCurrentCategory('life');
        setNewCategory('');
        editor.chain().setContent('<p></p>').run();
        cancelNote();
    }

    const saveExistingNote = ({ id, currentTitle, content, currentCategory, newCategory }) => {
        //console.log(newCategory);
        //console.log(currentCategory);
        const selectedCategory = (newCategory === '' ? currentCategory : newCategory.toLowerCase());
        //console.log(selectedCategory);

        if ((selectedCategory === 'add category') || (currentTitle === '') || content === '<p></p>') {
            //console.log('missing data');
            setCompleted(false);
        } else {
            content.replace(/<p><\/p>/g, '<br>');
            setCompleted(true);
            editNote(id, currentTitle, content, selectedCategory);
            clearNote();
        }
    }

    return (
        <div>
            <div className="add-accordion">
                <div className="input-set">
                    <label>Title</label>
                    <input className={(!completed && currentTitle === '') ? ('error') : ('no-error')} type="text" value={currentTitle} onChange={(e) => setCurrentTitle(e.target.value)} />
                </div>

                <div className='flex-set'>
                    <div className="input-set">
                        <label>Category</label>
                        <select id='selectCategory' value={currentCategory} onChange={(e) => setCurrentCategory(e.target.value)}>
                            {categories.map((categoryName, index) => (
                                <option key={index} value={categoryName}>
                                    {categoryName}
                                </option>
                            ))}
                            <option value='add category'>add category</option>
                        </select>
                    </div>
                    {currentCategory === 'add category' ? (
                        <div className="input-set">
                            <label>Category Name</label>
                            <input className={(!completed && newCategory === '') ? ('error') : ('no-error')} type="text" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
                        </div>) : null}

                </div>

                <div className="input-set tiptap">
                    <label className={(!completed && editor.getHTML() === '<p></p>') ? ('tiptap-error') : ('no-error')}>Content</label>
                    <Tiptap editor={editor} />
                </div>

                {(!completed && (currentTitle === '' || (category === 'add category' && newCategory === '') || editor.getHTML() === '<p></p>')) && (
                    < div className="error">
                        <label>You are missing information.  Fill in all fields to continue.</label>
                    </div>
                )}

                <div className="button-set">
                    <button className="save" onClick={() => saveExistingNote({ id, currentTitle, content: editor.getHTML(), currentCategory, newCategory })}>Save</button>
                    <button className="delete" onClick={() => deleteNote(id)}>delete</button>
                    <button className="cancel" onClick={clearNote}>cancel</button>
                </div>
            </div>
        </div>
    )

    /*<input type="text" value={content} onChange={(e) => setContent(e.target.value)} />*/
}

export default EditAccordion;