import { useState } from "react";
import Accordion from "./Accordion";
import AddAccordion from "./AddAccordion";
import Search from "./Search";

/*const AccordionList = ({ notes, handleAddNote, handleDeleteNote }) => {
    return (
        <div className="notes-list">
            {notes.map((note) => (
                <Note key={note.id} id={note.id} text={note.text} date={note.date} handleDeleteNote={handleDeleteNote} />
            ))}
            <AddNote handleAddNote={handleAddNote} />
        </div>
    )
}*/

const AccordionList = ({ notes, categories, showAddNote, showEditNote, handleClickAddNote, handleClickEditNote, addNote, cancelNote, deleteNote, editNote }) => {
    /*fix category showing in edit */
    const [showCategory, setShowCategory] = useState(true);

    const clickEditNote = () => {
        setShowCategory(false);
        handleClickEditNote();
        //console.log('showcategory: ' + showCategory);
    }

    const clickSaveNote = (id, title, content, category) => {
        setShowCategory(true);
        editNote(id, title, content, category);
        //console.log(content);
        //console.log('showcategory: ' + showCategory);
    }

    const clickDeleteNote = (id) => {
        setShowCategory(true);
        deleteNote(id);
        //console.log('showcategory: ' + showCategory);
    }

    const clickCancelNote = () => {
        setShowCategory(true);
        cancelNote();
        //console.log('showcategory: ' + showCategory);
    }

    return (
        <div>
            <div className="tabs">
                {/*<Search />*/}
                {!showEditNote &&
                    <AddAccordion categories={categories} showAddNote={showAddNote} handleClickAddNote={handleClickAddNote} addNote={addNote} cancelNote={cancelNote} />
                }

                {!showAddNote &&
                    categories.map((category, index) => (
                        <div key={index}>
                            {showCategory &&
                                <button key={index} className="tag">
                                    {category}
                                </button>
                            }

                            <div>
                                {notes
                                    .filter((note) => note.category === category)
                                    .map((note) => (
                                        <Accordion
                                            key={note.id}
                                            id={note.id}
                                            title={note.title}
                                            content={note.content}
                                            category={note.category}
                                            categories={categories}
                                            handleClickEditNote={clickEditNote}
                                            showEditNote={showEditNote}
                                            cancelNote={clickCancelNote}
                                            editNote={clickSaveNote}
                                            deleteNote={clickDeleteNote}
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}

            </div>
        </div>

    )
}

export default AccordionList;